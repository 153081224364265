import styled from 'styled-components';
import { propsToAttributes } from './utils';

export const Flex = styled.div`
  display: flex;
  ${propsToAttributes}
`;

export const ButtonsWrapper = styled(Flex)`
  display: flex;
  justify-content: end;
  gap: 8px;
`;
