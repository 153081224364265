import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';

import Icon from '../icon';
import useI18n from '../../../lib/use_i18n';
import {
  ModalBody,
  ModalHeadWrapper,
  ModalWrapper,
  Overlay,
  XButton,
} from '../styles/modal';
import { getPortalContainer } from '../lib/utils';

export const MODAL_WRAPPER_ID = 'modal-wrapper';
export const Modal = ({
  children,
  close,
  closeOnBlur,
  height,
  hideHeader,
  hideX,
  id,
  overlayStyle,
  shown,
  style,
  title,
  trackingIdPrefix,
  width,
}) => {
  const { translate } = useI18n('app.modal');
  const portalContainerRef = useRef(getPortalContainer(MODAL_WRAPPER_ID));
  return shown
    ? createPortal(
        <Overlay
          data-testid="overlay"
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopPropagation();
            e.nativeEvent.preventDefault();
            closeOnBlur && close();
          }}
          style={overlayStyle}
        >
          <ModalWrapper
            height={height}
            id={id}
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopPropagation();
            }}
            role="dialog"
            style={style}
            width={width}
          >
            {hideHeader ? null : (
              <ModalHeadWrapper>
                <h3>{title}</h3>
                {hideX ? null : (
                  <XButton
                    aria-label={translate('.x_button_label')}
                    data-trackingid={`${trackingIdPrefix}modal-x-button`}
                    id={'modal-x-button'}
                    onClick={close}
                  >
                    <Icon name="close" size={24} />
                  </XButton>
                )}
              </ModalHeadWrapper>
            )}
            <ModalBody> {children} </ModalBody>
          </ModalWrapper>
        </Overlay>,
        // without the container element, the native events would always bubble up to the document.body
        // Leaking unwanted events to the body element was problematic because useVisibility is handling
        // outside clicks utilizing body's click handler. Without the container element we would
        // face unwaranted hide actions from useVisibility hook.
        portalContainerRef.current
      )
    : null;
};

export const ModalPropTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.func,
  closeOnBlur: PropTypes.bool,
  height: PropTypes.string,
  hideHeader: PropTypes.bool,
  hideX: PropTypes.bool,
  id: PropTypes.string,
  overlayStyle: PropTypes.object,
  style: PropTypes.object,
  title: PropTypes.string,
  trackingIdPrefix: PropTypes.string,
  width: PropTypes.string,
};

Modal.propTypes = {
  ...ModalPropTypes,
  close: PropTypes.func.isRequired,
  shown: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  closeOnBlur: false,
  title: '',
  trackingIdPrefix: '',
};
